import React, { useState } from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);
  return (
    <LessonLayout
      current_step={15}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 15</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>Be ready to share your additions to your rubric in a class discussion.</p>
            <p>If time permits, your teacher may ask you to think about the following information.</p>
            <p>It is now common to see ads for prescription medications on TV, in magazines, or on the internet. Before the early 1980s, companies could not advertise these medications directly to the public. The United States and New Zealand are currently the only countries in the world that allow this type of advertising.</p>

            <UnorderedList>
              <li>Should companies be allowed to advertise prescription drugs directly to consumers?</li>
              <li>How might these ads benefit consumers?</li>
              <li>What are some possible problems related to these ads?</li>
            </UnorderedList>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>After students have time to write statements in their rubrics, hold a class discussion so they can share their ideas. The following are some ideas that students can include on their rubrics.</p>
            <UnorderedList>
              <li>The benefits of the treatment, medication, or intervention are clearly explained.</li>
              <li>Information about potential risks (and the probability of these risks) is provided.</li>
              <li>The information being analyzed includes information about potential costs (e.g., monetary, time) that may be associated with the intervention.</li>
              <li>The information specifies how a given intervention, treatment, or medication compares with other possible interventions.</li>
            </UnorderedList>

            <p><strong>Optional:</strong> If desired, hold a class discussion or debate about the following scenario:</p>
            <p>It is now common to see ads for prescription medications on TV, in magazines, or on the internet. Before the early 1980s, companies could not advertise these medications directly to the public. The United States and New Zealand are currently the only countries in the world that allow this type of advertising. Should companies be allowed to advertise prescription drugs directly to consumers? What are the benefits and risks related to this advertising?</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

